<template>
  <div class="password-change">
    <div class="order-wrap">
      <div class="order-title">修改密码</div>
      <div class="order-main">
        <a-form
          class="mod-form"
          layout="horizontal"
          labelAlign="left"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
          :form="form"
          @submit="onSubmit"
        >
          <a-form-item label="原始密码" v-if="event === 0">
            <a-input

              placeholder="请输入原始密码"
              type="password"
              v-decorator="[
                'oldPassword',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="新密码">
            <a-input

              placeholder="请输入新密码"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input

              placeholder="请再次输入新密码"
              type="password"
              v-decorator="[
                'confirmPassword',
                {
                  rules: [
                    {
                      validator: handleFieldValidate,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="验证码">
            <admin-slider-captcha
              ref="verify"

              @change="onCaptchVerify"
            >
            </admin-slider-captcha>
          </a-form-item>
          <a-form-item :wrapper-col="wrapperColOffset">
            <a-button
              block
              :loading="loading"
              type="primary"

              html-type="submit"
              >提交修改</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validate } from '@/utils/validate'
import { changePassword } from '@/api/user'

export default {
  name: 'passwordChange',
  data () {
    return {
      event: 0,
      labelCol: {
        span: 2
      },
      wrapperCol: {
        span: 6
      },
      wrapperColOffset: {
        span: 6,
        offset: 2
      },
      form: this.$form.createForm(this),
      token: '',
      loading: false
    }
  },
  methods: {
    handleFieldValidate (rule, value, callback) {
      validate(
        'password-change',
        this.form.getFieldsValue(),
        rule.fullField
      ).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    onSubmit (e) {
      e.preventDefault()

      this.form.validateFields(async (errors, values) => {
        if (!errors) {
          try {
            const { token } = this

            if (!token) {
              this.$modalError({
                content: '请拖动滑块完成验证！',
                centered: true
              })
              return
            }

            this.loading = true

            const params = {
              newPassword: values.password,
              oldPassword: values.oldPassword
            }

            await changePassword(params)

            this.$success({
              content: '修改密码成功，请重新登录！',
              centered: true,
              onOk: async () => {
                await this.$store.dispatch('user/logout')
                this.$router.push({ path: '/login', query: { redirect: '/' } })
              }
            })
          } catch (err) {
            this.$refs.verify.refresh()
            this.$modalError({
              content: err.message,
              centered: true
            })
            this.loading = false
          }
        }
      })
    },
    onCaptchVerify (value) {
      this.token = value
    }
  }
}
</script>

<style>
</style>
